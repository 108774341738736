import React, { Component} from 'react';
import "./Footer.css";
import "../../App.css";




class Footer extends Component {

    render() {
        return (
            <footer>
                2018 © Спортивный клуб Tonus
            </footer>
        );
    }
}

export default Footer;
